import { secureAuthEndpoint } from "@helpers/ssr";
import { AUTH_TOKEN, LOGIN } from "@localization";
import { coursesRedirect } from "@redirects";
import Auth from "./auth";

export async function getServerSideProps(ctx) {
  const { req } = ctx;
  if (req?.cookies[AUTH_TOKEN]) {
    return await secureAuthEndpoint({ redirect: coursesRedirect, ctx });
  }
  return {
    props: {
      nextProps: { authState: LOGIN, referer: req?.headers?.referer ?? null },
    },
  };
}

export default Auth;
